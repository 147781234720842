import { FC, KeyboardEvent, ReactNode, useEffect, useRef, useState } from 'react';
import { useField } from 'formik';
import cn from 'classnames';

import { Icon, Text, TextButton, Tooltip, FormikAutocomplete, Tag } from 'shared/components/ui';
import { Skill } from 'shared/generated-models';
import { useDebounce } from 'shared/hooks';

import styles from './index.module.scss';

interface Props {
	onChange?: (value: Array<any>) => void;
	label?: string;
	id?: string;
	placeholder?: string;
	useQuery: any;
	onClick?: () => void;
	name: string;
	className?: string;
	checkboxItem?: ReactNode;
}

const ITEMS_TO_SHOW = 5;

export const SearchAutocomplete: FC<Props> = ({
	label,
	useQuery,
	placeholder,
	onClick,
	name,
	className,
	checkboxItem
}: Props) => {
	const [options, setOptions] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');

	const isSkills = name === 'required_skills' || name === 'bonus_skills';

	const dropdownRef = useRef<HTMLDivElement | null>(null);

	const debouncedSearchQuery = useDebounce(
		isSkills
			? searchQuery.replace(/\s+/g, ' ').replaceAll(' ', '-').trim()
			: searchQuery.replace(/\s+/g, ' ').trim(),
		300
	);

	const [, meta, helper] = useField(name);
	const { value } = meta;

	const { data } = useQuery(debouncedSearchQuery);

	useEffect(() => {
		setOptions(
			data?.map((item: Skill) => {
				return {
					label: item.name,
					value: item.name
				};
			}) || []
		);
	}, [data]);

	useEffect(() => {
		dropdownRef.current?.scroll({ top: 0 });
	}, [options]);

	const firstFive = value?.slice(0, ITEMS_TO_SHOW);

	const handleSelect = (data: string) => {
		if (!value) {
			helper.setValue([data]);
			return;
		}

		if (!value?.includes(data)) {
			helper.setValue([...value!, data]);
			return;
		}

		handleDelete(data);
	};

	const handleEnterPress = (e: KeyboardEvent<HTMLElement>) => {
		if (e.key === 'Enter') e.preventDefault();
	};

	const handleClear = () => {
		helper.setValue([], true);
	};

	const handleDelete = (deleted: string) => {
		helper.setValue(value?.filter((item: string) => item !== deleted) || []);
	};

	return (
		<div className={cn(styles.wrapper, className)}>
			<div className={cn(styles.title, { [styles.titleMargin]: value && value.length > 0 })}>
				<Text className={styles.titleLabel}>
					{label}&nbsp;
					{value && value.length > 0 && <span>({value.length})</span>}
				</Text>
				{value && value.length > 0 && (
					<TextButton onClick={handleClear} className={styles.titleButton}>
						Clear
					</TextButton>
				)}
			</div>
			<div className={styles.tags}>
				{firstFive?.map((item: string) => (
					<Tag key={item} label={item} onClick={() => handleDelete(item)} />
				))}
				{value && value.length > ITEMS_TO_SHOW && (
					<Tooltip
						arrow={{ arrowPointAtCenter: true }}
						title={value && value.slice(ITEMS_TO_SHOW, value.length).join(' · ')}
						placement="right">
						<button type="button" onClick={onClick} className={styles.tag}>
							<Text variant="inter/14/medium">{value.length - ITEMS_TO_SHOW} More</Text>
						</button>
					</Tooltip>
				)}
			</div>
			<FormikAutocomplete
				name={name}
				onKeyDown={handleEnterPress}
				value={searchQuery}
				className={styles.autocomplete}
				dropdownRender={() => (
					<div ref={dropdownRef} className={cn({ [styles.autocompleteDropdown]: options.length > 5 })}>
						{options.map((item: { value: string; label: string }) => (
							<button
								key={item.value}
								onClick={() => handleSelect(item.value)}
								className={cn(styles.autocompleteOption, {
									[styles.autocompleteOptionSelected]: value?.includes(item.value)
								})}>
								<Text>{item.label}</Text>
								{value?.includes(item.value) && <Icon icon="check" />}
							</button>
						))}
					</div>
				)}
				onSelect={handleSelect}
				getPopupContainer={trigger => trigger.parentNode}
				onSearch={setSearchQuery}
				options={options}
				placeholder={placeholder}
			/>
			{checkboxItem}
		</div>
	);
};
