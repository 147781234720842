import { FC, useEffect } from 'react';

import { useLocalStorage } from 'shared/hooks';
import { useGetCurrentUserQuery } from 'services';
import { ContactUsModal } from '../ContactUsModal';

import { useIsFree } from '../../hooks';

interface Props {
	onClose?: () => void;
}

export const OutOfCreditsModal: FC<Props> = ({ onClose }: Props) => {
	const { data } = useGetCurrentUserQuery();
	const isFree = useIsFree();

	const [shouldShowCreditsModal, setShouldShowCreditsModal] = useLocalStorage('shouldShowCreditsModal', true);

	useEffect(() => {
		if (data && data.credits >= 50) {
			setShouldShowCreditsModal(true);
		}
	}, [data]);

	const handleClose = () => {
		setShouldShowCreditsModal(false);
		onClose?.();
	};

	if (!isFree && (data && data.credits < 50) && shouldShowCreditsModal)
		return (
			<ContactUsModal onClose={handleClose} isExpiring={true}/>
		);

	return null;
};
