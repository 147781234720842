import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Form } from 'antd';
import cn from 'classnames';

import {
	Button,
	Checkbox,
	TextButton,
	Text,
	FormikCheckbox,
	FormikCheckboxGroup,
	FormikTextArea,
	Icon,
	AdvancedTooltip
} from 'shared/components/ui';
import { SearchParams, SeniorityEnum, YoEmploymentEnum, YoExperienceEnum } from 'shared/generated-models';
import { SearchAutocomplete, SearchModal } from 'features/search/components';
import {
	useGetCompaniesQuery,
	useGetCountriesQuery,
	useGetJobTitlesQuery,
	useGetLanguagesQuery,
	useGetLocationsQuery,
	useGetProgrammingLanguagesQuery,
	useGetRegionsQuery,
	useGetRepositoriesQuery,
	useGetSchoolsQuery,
	useGetSkillsQuery,
	useGetSubregionsQuery
} from 'services';
import { useFilters, useGetCandidates, useSearch } from 'features/search/hooks';
import { formIsEmpty } from 'shared/utils';
import { saveSearch } from 'features/search/utils';

import styles from './index.module.scss';

export const SideSearchForm = () => {
	const [openModal, setOpenModal] = useState(false);
	const [isOpen, setIsOpen] = useState(true);
	const filters = useFilters();
	const search = useSearch();
	const location = useLocation();

	const { page, size, ...params } = filters;
	useEffect(() => {
		saveSearch(location.search, params);
	}, []);

	const { isFetching } = useGetCandidates();

	const formik = useFormik<SearchParams>({
		initialValues: filters,
		validateOnMount: true,
		enableReinitialize: true,
		onSubmit: values => {
			search({ ...values, page: 0 }, { refresh: true });
			setOpenModal(false);
		}
	});

	const formHasValue = !formIsEmpty({ ...formik.values, size: undefined, page: undefined });

	const openForm = () => {
		setOpenModal(true);
	};

	const closeForm = () => {
		setOpenModal(false);
	};

	const handleOpen = () => {
		setIsOpen(isOpen => !isOpen);
	};

	const clearFilters = () => {
		formik.setValues({});
	};

	return (
		<>
			<Button className={styles.openButton} onClick={handleOpen}>
				<Icon icon="filter" />
			</Button>
			<section className={cn(styles.formContainer, { [styles.formContainerOpen]: isOpen })}>
				<div className={styles.formTitle}>
					<Text component="span" color="grey" variant="inter/14/medium">
						Filters
					</Text>
					{formHasValue && <TextButton onClick={clearFilters}>Clear all</TextButton>}
				</div>
				<FormikProvider value={formik}>
					<Form layout="vertical">
						<div className={styles.formHeight}>
							<div className={cn(styles.formField, styles.formItem)}>
								<FormikTextArea
									name="text"
									label="Skills name"
									size="small"
									placeholder="Enter skills"
								/>
							</div>
							{/* <SearchAutocomplete
								name="required_skills"
								className={styles.formField}
								useQuery={useGetSkillsQuery}
								onClick={openForm}
								label="Required skills"
								placeholder="Enter or select Required skills"
							/>
							<SearchAutocomplete
								name="bonus_skills"
								className={styles.formField}
								useQuery={useGetSkillsQuery}
								onClick={openForm}
								label="Bonus skills"
								placeholder="Enter or select Bonus skills"
							/> */}
							<SearchAutocomplete
								name="prog_langs"
								className={styles.formField}
								useQuery={useGetProgrammingLanguagesQuery}
								onClick={openForm}
								label="Programming languages"
								placeholder="Pick programming languages"
							/>
							<SearchAutocomplete
								name="github_repos"
								className={styles.formField}
								useQuery={useGetRepositoriesQuery}
								onClick={openForm}
								label="GitHub Repositories"
								placeholder="Select GitHub Repositories"
							/>
							<div className={cn(styles.formField, styles.formFieldCheckbox)}>
								<div className={styles.likelyToMove}>
									<Text component="p">Likely to move</Text>
								</div>
								<div className={styles.topSchool}>
									<FormikCheckbox name="likely_to_move_prob">Likely to move</FormikCheckbox>
								</div>
							</div>
							<SearchAutocomplete
								name="job_titles"
								className={styles.formField}
								useQuery={useGetJobTitlesQuery}
								onClick={openForm}
								label="Job title"
								placeholder="Enter or select Job title"
							/>
							<FormikCheckboxGroup
								label={<Text>Seniority</Text>}
								name="seniority"
								className={cn(styles.formField, styles.formFieldCheckbox)}>
								<Checkbox value={SeniorityEnum.SENIOR}>Senior</Checkbox>
								<Checkbox value={SeniorityEnum.MIDDLE}>Middle</Checkbox>
								<Checkbox value={SeniorityEnum.JUNIOR}>Junior</Checkbox>
								<div className={styles.rockstar}>
									<Text component="p" className={styles.rockstarLabel}>
										Rock Star
									</Text>
									<Checkbox value={SeniorityEnum.ROCK_STAR}>Rock Star</Checkbox>
								</div>
							</FormikCheckboxGroup>
							<FormikCheckboxGroup
								label={<Text>Years of employment in software</Text>}
								name="yo_employment"
								className={cn(styles.formField, styles.formFieldCheckbox)}>
								<Checkbox value={YoEmploymentEnum._10_}>10+</Checkbox>
								<Checkbox value={YoEmploymentEnum._5_10}>5-10</Checkbox>
								<Checkbox value={YoEmploymentEnum._3_5}>3-5</Checkbox>
								<Checkbox value={YoEmploymentEnum._0_3}>0-3</Checkbox>
							</FormikCheckboxGroup>
							<FormikCheckboxGroup
								label={<Text>Years of coding experience</Text>}
								name="yo_experience"
								className={cn(styles.formField, styles.formFieldCheckbox)}>
								<Checkbox value={YoExperienceEnum._10_}>10+</Checkbox>
								<Checkbox value={YoExperienceEnum._5_10}>5-10</Checkbox>
								<Checkbox value={YoExperienceEnum._3_5}>3-5</Checkbox>
								<Checkbox value={YoExperienceEnum._0_3}>0-3</Checkbox>
							</FormikCheckboxGroup>
							<SearchAutocomplete
								name="companies"
								className={styles.formField}
								useQuery={useGetCompaniesQuery}
								onClick={openForm}
								label="Company"
								placeholder="Enter Companies"
								checkboxItem={
									<div className={styles.checkbox}>
										<FormikCheckbox name="is_current_companies">
											<Text variant="inter/14/regular" color="grey">
												Currently working in the company
											</Text>
										</FormikCheckbox>
									</div>
								}
							/>
							<SearchAutocomplete
								name="companies_to_exclude"
								className={styles.formField}
								useQuery={useGetCompaniesQuery}
								onClick={openForm}
								label="Excluded companies"
								placeholder="Enter or select Companies to exclude from search"
								checkboxItem={
									<div className={styles.checkbox}>
										<FormikCheckbox name="is_current_companies_to_exclude">
											<Text variant="inter/14/regular" color="grey">
												Currently not working in the company
											</Text>
										</FormikCheckbox>
									</div>
								}
							/>
							<SearchAutocomplete
								name="locations"
								className={styles.formField}
								useQuery={useGetLocationsQuery}
								onClick={openForm}
								label="City or state"
								placeholder="Enter city or state"
							/>
							<SearchAutocomplete
								name="sub_regions"
								className={styles.formField}
								useQuery={useGetSubregionsQuery}
								onClick={openForm}
								label="Subregion"
								placeholder="Enter Subregion"
							/>
							<SearchAutocomplete
								name="regions"
								className={styles.formField}
								useQuery={useGetRegionsQuery}
								onClick={openForm}
								label="Region"
								placeholder="Enter Region"
							/>
							<SearchAutocomplete
								name="countries"
								className={styles.formField}
								useQuery={useGetCountriesQuery}
								onClick={openForm}
								label="Country"
								placeholder="Enter Country"
							/>
							<div className={cn(styles.formField, styles.formFieldCheckbox)}>
								<Text component="p" className={styles.rockstarLabel}>
									Top Schools
								</Text>
								<div className={styles.topSchool}>
									<FormikCheckbox name="is_top_school">
										Top schools in computer science
									</FormikCheckbox>
								</div>
							</div>
							<SearchAutocomplete
								name="top_schools"
								className={styles.formField}
								useQuery={useGetSchoolsQuery}
								onClick={openForm}
								label="Education"
								placeholder="Enter or select Schools, Universities"
							/>
							<SearchAutocomplete
								name="languages"
								className={styles.formField}
								useQuery={useGetLanguagesQuery}
								onClick={openForm}
								label="Languages"
								placeholder="Enter or select Languages"
							/>
							<div className={cn(styles.formField, styles.formFieldCheckbox)}>
								<Text component="p" className={styles.rockstarLabel}>
									Diversity filters
								</Text>
								<div className={styles.topSchool}>
									<FormikCheckbox name="is_first_name_female">
										Name contains a female first name
									</FormikCheckbox>
								</div>
							</div>
						</div>
						<div className={styles.formButtons}>
							<Button
								type="ghost"
								prefix={<Icon icon="filter" className={styles.formButtonsFilter} />}
								onClick={openForm}>
								All filters
							</Button>
							<Button
								type="secondary"
								onClick={formik.handleSubmit}
								loading={isFetching}
								disabled={!formHasValue}>
								{isFetching ? 'Searching' : 'Show candidates'}
							</Button>
						</div>
					</Form>
					{openModal && <SearchModal onClose={closeForm} />}
				</FormikProvider>
			</section>
		</>
	);
};
