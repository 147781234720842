import { memo } from 'react';
import { Checkbox } from 'antd';

import { ProgAIResults } from 'shared/generated-models';
import { CandidateProfileAvatar, ProfileName } from 'shared/components';
import { getProfileInitials } from 'shared/utils';
import { Icon, LikelyToMoveTag, MatchTag, openNotification, RockstarTag, Text } from 'shared/components/ui';
import { TableSelectionMode, Tr } from 'features/table';
import { formatLocationShort, formatTitle } from 'features/search/utils';
import { ProfileContacts } from 'features/search/components';

import styles from './index.module.scss';

interface Props {
	onClick: () => void;
	checkbox: {
		checked: boolean;
		onChange: () => void;
		mode: TableSelectionMode;
	};
	candidate: ProgAIResults;
	openedContactsMap: Record<number, string> | null;
}

const ITEMS_TO_SHOW = 5;

export const ProfileRow = memo(({ onClick, checkbox, candidate, openedContactsMap }: Props) => {
	const { match_metadata, profile } = candidate;

	// const isOpen = openedContactsMap ? openedContactsMap.hasOwnProperty(profile.id) : false;
	// const email = openedContactsMap && isOpen ? openedContactsMap[profile.id] : '';

	const skills = match_metadata?.found_skills?.slice(0, ITEMS_TO_SHOW);

	const title = formatTitle(profile);
	const location = formatLocationShort(profile.location, profile.country);

	const isRockStar = profile.seniority_level === 'Rock Star';

	const handleRate = () => {
		openNotification({ text: 'Thanks for the feedback!', closable: true });
	};

	return (
		<Tr className={styles.profile} onClick={onClick}>
			<div className={styles.border}></div>
			<div className={styles.wrapper}>
				<Checkbox className={styles.profileCheckbox} checked={checkbox.checked} onChange={checkbox.onChange} />
				{/* <ConfiguredTooltip isOpen={isOpen} delay={1}>
					<CandidateProfileAvatar
						onClick={onClick}
						src={profile.profile_pic_url}
						size={80}
						className={cn(styles.profileAvatar, { [styles.profileAvatarBlurred]: !isOpen })}>
						{getProfileInitials(profile)}
					</CandidateProfileAvatar>
				</ConfiguredTooltip> */}
				<CandidateProfileAvatar
					onClick={onClick}
					src={profile.profile_pic_url}
					size={80}
					className={styles.profileAvatar}>
					{getProfileInitials(profile)}
				</CandidateProfileAvatar>
				<div className={styles.profileSummary}>
					<div className={styles.profileHeader}>
						{/* <ConfiguredTooltip isOpen={isOpen} delay={1}>
							<span onClick={onClick}>
								<ProfileName
									variant="inter/16/semi"
									profile={profile}
									className={cn(styles.profileName, { [styles.profileBlurred]: !isOpen })}
								/>
							</span>
						</ConfiguredTooltip> */}
						<ProfileName variant="inter/16/semi" profile={profile} className={styles.profileName} />
						<div className={styles.profileHeaderRight}>
							<ProfileContacts candidate={candidate.profile} />
							<div className={styles.rate}>
								<div className={styles.rateIcon} onClick={handleRate}>
									<Icon size={20} icon="thumbs-up" />
								</div>
								<div className={styles.rateIcon} onClick={handleRate}>
									<Icon size={20} icon="thumbs-down" />
								</div>
							</div>
						</div>
					</div>
					<div className={styles.profileBody}>
						<div className={styles.profileTitleWrapper}>
							{title && (
								<Text variant="inter/13/medium" paragraph className={styles.profileTitle}>
									{title}
								</Text>
							)}
							<Text variant="inter/13/regular" color="darker_grey">
								{profile.years_of_experience &&
									(profile.years_of_experience > 10
										? `10+ y experience`
										: `${profile.years_of_experience} y experience`)}
							</Text>
						</div>
						<div className={styles.row}>
							<Icon icon="location" size={20} className={styles.icon} />
							<Text variant="inter/13/regular" color="darker_grey">
								{location ? location : 'N/A'}
							</Text>
						</div>
					</div>
					{match_metadata && match_metadata.match_score && (
						<div className={styles.profileSkills}>
							<MatchTag score={match_metadata.match_score} />
							<LikelyToMoveTag prob={profile.likely_to_move_prob} />
							{isRockStar && <RockstarTag />}
							{skills &&
								skills.map(element => (
									<Text
										variant="inter/12/medium"
										component="p"
										key={element.skill}
										className={styles.profileSkill}>
										{element.skill}
									</Text>
								))}
							{match_metadata.found_skills && match_metadata.found_skills.length > ITEMS_TO_SHOW && (
								<Text variant="inter/12/medium" component="p" className={styles.profileSkill}>
									+{match_metadata.found_skills.length - 5} more
								</Text>
							)}
						</div>
					)}
				</div>
			</div>
		</Tr>
	);
}, arePropsEqual);

function arePropsEqual(oldProps: Props, newProps: Props) {
	return (
		oldProps.checkbox.checked === newProps.checkbox.checked &&
		oldProps.checkbox.mode === newProps.checkbox.mode &&
		oldProps.openedContactsMap === newProps.openedContactsMap
	);
}
