import { FC, useRef, useState } from 'react';
import cn from 'classnames';

import { Icon } from 'shared/components/ui';
import { useAutosizeTextarea } from 'features/search/hooks';

import styles from './index.module.scss';

interface Props {
	value?: string;
	onChange: (value: string) => void;
}

export const SearchText: FC<Props> = ({ value, onChange }: Props) => {
	const [isFocused, setIsFocused] = useState(false);
	const ref = useRef<HTMLTextAreaElement | null>(null);

	useAutosizeTextarea(ref.current, value || '');

	const handleFocus = () => {
		setIsFocused(true);

		ref.current?.focus();
	};

	const handleBlur = () => {
		setIsFocused(false);
	};

	return (
		<div
			className={cn(styles.search, { [styles.searchFocus]: isFocused })}
			tabIndex={1}
			onFocus={handleFocus}
			onBlur={handleBlur}>
			<Icon icon="search" size={20} className={styles.searchIcon} />
			<div className={styles.wrapper}>
				<textarea
					ref={ref}
					autoFocus
					onBlur={handleBlur}
					placeholder="Job title, skills, or any keywords"
					value={value}
					rows={1}
					onChange={e => onChange(e.target.value)}
					className={styles.searchInput}
				/>
			</div>
		</div>
	);
};
