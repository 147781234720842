import React, { FC, useState } from 'react';
import cn from 'classnames';

import { useGetCurrentUserQuery } from 'services';
import { getProfileInitials } from 'shared/utils';
import { CandidateProfileAvatar, ReadMoreText, ProfileName } from 'shared/components';
import { ConfiguredTooltip, Icon, MatchTag, LikelyToMoveTag, RockstarTag, Text } from 'shared/components/ui';
import {
	CandidateSkills,
	CandidateSection,
	CandidateProfileSkeleton,
	CandidateContacts,
	CandidateInfo
} from 'features/candidate/components';
import { ProgAIResults, ProgAICandidate } from 'shared/generated-models';
import { ContactModal, ProfileVariant } from 'features/candidate';
import { NoCreditsModal } from 'features/auth/components';
import { formatLocation } from 'features/search/utils';

import styles from './index.module.scss';

interface Props {
	candidate?: ProgAIResults;
	data?: ProgAICandidate['profile'];
	shared?: boolean;
	className?: string;
	variant: ProfileVariant;
	isFetching?: boolean;
}

export const CandidateProfile: FC<Props> = ({ candidate, data, shared, variant, className, isFetching }: Props) => {
	const { data: user } = useGetCurrentUserQuery();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [notEnoughCreditsModalOpen, setNotEnoughCreditsModalOpen] = useState(false);

	if (!data || isFetching) return <CandidateProfileSkeleton />;

	const id = variant === 'projects' ? data!.prog_ai_id : data!.id;

	const openModal = () => {
		if (user && user.credits < 1) {
			setNotEnoughCreditsModalOpen(true);
		} else {
			setIsModalOpen(true);
		}
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const closeIsNotEnoughCreditsModalOpen = () => {
		setNotEnoughCreditsModalOpen(false);
	};

	const isOpenProfile = !!data.emails;
	const isRockstar = data.seniority_level === 'Rock Star';
	const hasHighlights =
		candidate?.match_metadata?.match_score ||
		isRockstar ||
		!!(data.likely_to_move_prob && data.likely_to_move_prob >= 0.6);

	const formatTitle = (data: ProgAICandidate['profile']) => {
		if (data.company && data.company === 'Self Employed') return data.company;
		if (!data.company) return data.title;
		if (!data.title) return '';
		if (data.title.includes('at')) return data.title;
		if (data.title.includes(data.company)) return data.title;
		return (
			<>
				{data.title} at <span>{data.company}</span>
			</>
		);
	};

	return (
		<>
			<div className={styles.wrapper}>
				<section className={styles.info}>
					{/* <ConfiguredTooltip isOpen={isOpenProfile}>
						<CandidateProfileAvatar
							onClick={isOpenProfile ? undefined : openModal}
							src={data.profile_pic_url}
							size={120}
							className={cn(styles.infoAvatar, { [styles.infoAvatarBlurred]: !isOpenProfile })}>
							{getProfileInitials(data)}
						</CandidateProfileAvatar>
					</ConfiguredTooltip> */}
					<CandidateProfileAvatar src={data.profile_pic_url} size={120} className={styles.infoAvatar}>
						{getProfileInitials(data)}
					</CandidateProfileAvatar>
					<div className={styles.infoSummary}>
						{/* <ConfiguredTooltip isOpen={isOpenProfile}>
							<span onClick={isOpenProfile ? undefined : openModal}>
								<ProfileName
									variant="inter/24/semi"
									profile={data}
									className={cn(styles.infoName, { [styles.infoBlurred]: !isOpenProfile })}
								/>
							</span>
						</ConfiguredTooltip> */}
						<ProfileName variant="inter/24/semi" profile={data} className={styles.infoName} />
						{hasHighlights && (
							<div className={styles.highlights}>
								{candidate?.match_metadata && candidate?.match_metadata.match_score && (
									<MatchTag score={candidate.match_metadata.match_score} onProfile={true} />
								)}
								<LikelyToMoveTag
									showPercentage={true}
									prob={data.likely_to_move_prob}
									onProfile={true}
								/>
								{isRockstar && <RockstarTag onProfile={true} />}
							</div>
						)}
						<Text color="dark_green" variant="inter/14/regular" paragraph className={styles.infoTitle}>
							{formatTitle(data)}
						</Text>
						{data.location && (
							<div className={styles.location}>
								<Icon icon="location" size={20} className={styles.locationIcon} />
								<Text variant="inter/14/regular" color="darker_grey">
									{formatLocation(data.location, data.country)}
								</Text>
							</div>
						)}
					</div>
					{!shared && <CandidateContacts data={data} variant={variant} />}
				</section>
				<section className={cn(styles.profile, className)}>
					<div>
						<div className={styles.sections}>
							<CandidateInfo
								data={data}
								metadata={candidate?.match_metadata}
								variant={variant}
								shared={shared}
							/>
						</div>
						<div className={styles.sections}>
							{data.oss_contributions && (
								<CandidateSection
									key={data.id}
									title={`Github Repositories (${data.oss_contributions.length})`}
									icon="github-logo-circle"
									array={data.oss_contributions}
									sectionName={'oss_contribution'}
									isClosedProfile={!isOpenProfile}
									shared={shared}
								/>
							)}
							{data.work_experiences && (
								<CandidateSection
									title="Experience"
									array={data.work_experiences}
									sectionName={'experience'}
									shared={shared}
								/>
							)}
							{data.educations && (
								<CandidateSection
									title="Education"
									array={data.educations}
									sectionName={'education'}
									shared={shared}
								/>
							)}
							{data.certifications && (
								<CandidateSection
									title="Licences &amp;&nbsp;certifications"
									array={data.certifications}
									sectionName={'certification'}
									shared={shared}
								/>
							)}
							{data.awards && (
								<CandidateSection
									title="Honors &amp;&nbsp;awards"
									array={data.awards}
									sectionName={'award'}
									shared={shared}
								/>
							)}
							{data.publications && (
								<CandidateSection
									title="Publications"
									array={data.publications}
									sectionName={'publication'}
									shared={shared}
								/>
							)}
							{data.patents && (
								<CandidateSection
									title="Patents"
									array={data.patents}
									sectionName={'patent'}
									shared={shared}
								/>
							)}
							{data.languages && (
								<CandidateSection
									title="Languages"
									array={data.languages}
									sectionName={'language'}
									shared={shared}
								/>
							)}
						</div>
					</div>
					{((data.skills && data.skills.length > 0) ||
						(data.programming_languages && data.programming_languages.length > 0)) && (
						<CandidateSkills data={data} />
					)}
				</section>
			</div>
			{isModalOpen && <ContactModal onClose={closeModal} id={id!} variant={variant} />}
			{notEnoughCreditsModalOpen && <NoCreditsModal onClose={closeIsNotEnoughCreditsModalOpen} />}
		</>
	);
};
