import { Avatar } from 'antd';
import { Link } from 'react-router-dom';

import { Icon, Dropdown, GradientBox } from 'shared/components/ui';
import { getFullName, getInitials } from 'shared/utils';
import { clearTokens, useGetCurrentUserQuery, useLogoutUserMutation } from 'services';

import styles from './index.module.scss';

export const HeaderV2 = () => {
	const { data } = useGetCurrentUserQuery();
	const [logoutUser] = useLogoutUserMutation();

	const handleLogout = () => {
		logoutUser().then(() => {
			clearTokens();
		});
	};

	const items = [
		{
			label: (
				<Link to="/profile" className={styles.dropdownImportant}>
					{getFullName(data)}
				</Link>
			),
			key: '0'
		},
		{
			label: (
				<Link to="/login" onClick={handleLogout}>
					Logout
				</Link>
			),
			key: '2'
		}
	];

	return (
		<div className={styles.profileInfo}>
			<Dropdown menu={{ items }} trigger={['click']} overlayClassName={styles.profileAccountOverlay}>
				<div className={styles.profileAccount}>
					<GradientBox className={styles.gradientBox} backgroundClassName={styles.gradientBoxBefore}>
						<Avatar size={24} src={data?.avatar}>
							{getInitials(data)}
						</Avatar>
					</GradientBox>
					<Icon className={styles.profileAccountDropdown} icon="arrow-down" />
				</div>
			</Dropdown>
		</div>
	);
};
